<template>
	<div class="data-content">
		<div>
			<el-form :model="form" label-position="top">
				<div class="form-section">
					<div class="section-header">
						<h3> 维修单 </h3>
						<el-dropdown placement="left" @command="handleCommand">
							<el-button type="primary" size="small" class="submit"> 下载</el-button>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item command="default">默认模板</el-dropdown-item>
									<el-dropdown-item command="refund">退换机申请书</el-dropdown-item>
									<el-dropdown-item command="maintenanceReport">理光特约维修店维修报告
									</el-dropdown-item>
									<el-dropdown-item command="maintenanceReportBlank">理光特约维修店维修报告（空白）
									</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
					<el-divider class="mt0"></el-divider>
					<div class="section-content">
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="流水号"> {{ form.serialNumber || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="维修区分"> {{ repairTypeText || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="特约维修店名"> {{ form.dealerName || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="6">
								<el-form-item label="机号"> {{ form.machineNo || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="商品编号"> {{ form.productCode || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="机型编号"> {{ form.modelCode || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="机型"> {{ form.modelName || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="出库日期"> {{ form.outDate || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="保修期结束日"> {{ form.warrantyEndDate || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="保修期"> {{ warrantyPeriodText || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="PPS号"> {{ form.ppsNo || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="是否需要上传发票">
									<el-tag v-if="form.invoiceRequired === 1" type="primary">是</el-tag>
									<el-tag v-else-if="form.invoiceRequired === 0" type="success">否</el-tag>
									<template v-else>--</template>
								</el-form-item>
							</el-col>
							<template v-if="form.invoiceRequired">
								<el-col :span="8">
									<el-form-item label="销售日期"> {{
											form.repairInvoice.saleDate || "--"
										}}
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="发票附件">
										<FileList :fileList="invoiceFileList"></FileList>
									</el-form-item>
								</el-col>
							</template>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="维修受理日期"> {{ form.repairAcceptDate || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="出发时间"> {{ form.departureTime || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="到达时间（维修开始时间）"> {{
										form.arrivalTime || "--"
									}}
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="服务形式"> {{ serviceTypeText || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="维修合同形态"> {{ repairContractTypeText || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="维修作业项目"> {{ repairItemsText || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="黑白计数器读数/投影机使用时间"> {{
										form.bwCounter || "--"
									}}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="彩色计数器读数/灯泡使用时间"> {{
										form.colorCounter || "--"
									}}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="制版计数器读数"> {{ form.plateCounter || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="是否投影仪">
									<el-tag v-if="form.isProjector === 1 || form.isProjector === '1'" type="primary">
										是
									</el-tag>
									<el-tag v-else-if="form.isProjector === 0 || form.isProjector === '0'"
											type="success">否
									</el-tag>
									<template v-else>--</template>
								</el-form-item>
							</el-col>
							<template v-if="form.isProjector">
								<el-col :span="8">
									<el-form-item label="使用时间">
										<template v-if="form.repairProjector && form.repairProjector.usageHours">
											{{ form.repairProjector.usageHours }}
										</template>
										<template v-else>--</template>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="除尘频率">
										<template v-if="form.repairProjector && form.repairProjector.dustFrequency">
											{{ form.repairProjector.dustFrequency }}
										</template>
										<template v-else>--</template>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="油烟环境">
										<template v-if="form.repairProjector && form.repairProjector.smokeEnvironment">
											{{ form.repairProjector.smokeEnvironment }}
										</template>
										<template v-else>--</template>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="箱体环境">
										<template v-if="form.repairProjector && form.repairProjector.caseEnvironment">
											{{ form.repairProjector.caseEnvironment }}
										</template>
										<template v-else>--</template>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="其他备注">
										<template v-if="form.repairProjector && form.repairProjector.remarks">
											{{ form.repairProjector.remarks }}
										</template>
										<template v-else>--</template>
									</el-form-item>
								</el-col>
							</template>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="服务费已支付"> {{ form.serviceFeePaid || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="服务费支付公司"> {{ form.paymentCompany || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="服务费支付日期"> {{ form.paymentDate || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item label="预计维修完成时间"> {{
										form.expectedCompletionTime || "--"
									}}
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="区域">
									<el-tag v-if="form.region === 1 || form.region === '1'" type="primary">华东</el-tag>
									<el-tag v-else-if="form.region === 2 || form.region === '2'" type="success">华南
									</el-tag>
									<el-tag v-else-if="form.region === 3 || form.region === '3'" type="warning">华北
									</el-tag>
									<template v-else>--</template>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="受理时随机附件以及送修商品外观备注">
									{{ form.receptionAttachments || "--" }}
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="用户公司"> {{ form.customerCompany || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户代码"> {{ form.customerCode || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="邮政编码"> {{ form.postalCode || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="送修人/保修人"> {{ form.contactPerson || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="联系电话"> {{ form.contactPhone || "--" }}</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="结算状态">
									{{ (form.settlementStatus === '1' ? "是" : "否") || "--" }}
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="地址"> {{ address || "--" }}</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="详细地址"> {{ form.addressDetail || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="客户应付维修费">
									{{ formatPriceString(form.customerRepairFee, '元/次') || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付上门费">
									{{ formatPriceString(form.customerVisitFee, '元/次') || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付零件费">
									{{ formatPriceString(form.customerPartsFee, '元/次') || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付耗材费">
									{{ formatPriceString(form.customerSuppliesFee, '元/次') || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付其他费用">
									{{ formatPriceString(form.customerOtherFee, '元') }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户其它费用说明"> {{
										form.customerOtherFeeNotes || "--"
									}}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="特约维修服务费">
									{{ formatPriceString(form.dealerServiceFee, '元/次') || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="委托维修服务费">
									{{ formatPriceString(form.entrustServiceFee, '元/次') || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="其他费用"> {{
										formatPriceString(form.otherFees, '元') || "--"
									}}
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="备注"> {{ form.remarks || "--" }}</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="form-section">
					<h3> 维修结果 </h3>
					<el-divider class="mt0"></el-divider>
					<div class="section-content">
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="修理完成日期">
									{{ form.repairResult && form.repairResult.repairFinishedTime || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="用户取回机器日期">
									{{ form.repairResult && form.repairResult.pickupDate || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="维修人姓名">
									{{ form.repairResult && form.repairResult.repairOper || "--" }}
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="维修结果"
											  v-if="(form.repairResult && form.repairResult.repairStatus == 1)&& (form.repairResult && form.repairResult.repairStatus == '1')">
									已修复
								</el-form-item>
								<el-form-item label="维修结果"
											  v-else-if="(form.repairResult && form.repairResult.repairStatus == 2) && (form.repairResult && form.repairResult.repairStatus == '2')">
									其它
								</el-form-item>
								<el-form-item label="维修结果" v-else>--</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="form-section">
					<h3> 故障 </h3>
					<el-divider class="mt0"></el-divider>

					<div class="section-content">
						<template v-if="faultPhen">
							<div class="section-title"> 故障现象</div>
							<table class="form-table">
								<thead>
								<tr>
									<th> 分类</th>
									<th> 现象</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item> {{ faultPhenTypeText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultPhenText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultPhen.remark || "--" }}</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>

						<template v-if="faultCause">
							<div class="section-title"> 故障原因</div>
							<table class="form-table">
								<thead>
								<tr>
									<th> 分类</th>
									<th> 原因</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item> {{ faultCauseTypeText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultCauseText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultCause.remark || "--" }}</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>

						<template v-if="faultMeasures">
							<div class="section-title"> 故障措施</div>
							<table class="form-table">
								<thead>
								<tr>
									<th> 分类</th>
									<th> 措施</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item> {{ faultMeasuresTypeText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultMeasuresText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultMeasures.remark || "--" }}</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>

						<template v-if="faultPosition">
							<div class="section-title"> 故障部位</div>
							<table class="form-table">
								<thead>
								<tr>
									<th> 分类</th>
									<th> 部位</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item> {{ faultPositionTypeText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultPositionText || "--" }}</el-form-item>
									</td>
									<td>
										<el-form-item> {{ faultPosition.remark || "--" }}</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>
					</div>
				</div>
				<div class="form-section">
					<h3> 主机/零件/选购件或耗材 </h3>
					<el-divider class="mt0"></el-divider>

					<div class="section-content">
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="是否需要主机/零件/选购件或耗材">
									<el-tag v-if="form.hasMainParts === 1 || form.hasMainParts === '1'" type="primary">
										主机
									</el-tag>
									<el-tag v-else-if="form.hasMainParts === 2 || form.hasMainParts === '2'"
											type="success">零件
									</el-tag>
									<el-tag v-else-if="form.hasMainParts === 3 || form.hasMainParts === '3'"
											type="warning">不需要
									</el-tag>
									<template v-else>--</template>
								</el-form-item>
							</el-col>
							<el-col v-if="form.hasMainParts === 2" :span="24">
								<el-form-item label="是否可以在零件未到货的情况下完成维修">
									<el-tag
										v-if="form.canRepairWithoutParts === 1 || form.canRepairWithoutParts === '1'"
										type="primary">可以
									</el-tag>
									<el-tag
										v-else-if="form.canRepairWithoutParts === 0 || form.canRepairWithoutParts === '0'"
										type="success">不可以
									</el-tag>
									<template v-else>--</template>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row v-if="form.hasMainParts === 1 || form.hasMainParts === 2" :gutter="20">
							<el-col :span="24">
								<el-form-item label="主机/零件/选购件或耗材" class="mb0"></el-form-item>
								<table class="form-table fixed-table">
									<thead>
									<tr style="font-size: 14px;">
										<th style="width: 3%"> 序号</th>
										<th style="width: 17%"> 故障零件分类</th>
										<th style="width: 16%"> 零件/耗材编号</th>
										<th style="width: 17%"> 零件编号商品变更</th>
										<th style="width: 17%"> 零件/耗材名称</th>
										<th style="width: 8%"> 零件/耗材数量</th>
										<th style="width: 17%"> 故障零件序列号码</th>
										<th style="width: 5%"> 是否申请</th>
									</tr>
									</thead>
									<tbody>
									<tr v-for="(item, i) in form.mainPartsList">
										<td class="text-center"> {{ i + 1 }}</td>
										<td>
											<el-form-item class="mb0"> {{
													getFaultyPartCategoryText(form.mainPartsList[i].faultyPartCategory)
												}}
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												{{ form.mainPartsList[i].partConsumableNumber || "--" }}
											</el-form-item>
										</td>
										<td v-if="showEdit()">
											{{ form.mainPartsList[i].partConsumableNumberNew || "--"}}
										</td>
										<td v-else>
											{{ form.mainPartsList[i].partConsumableNumberNew || ""}}
											<i class="el-icon-edit" @click="handleEdit(form.mainPartsList[i])"></i>
										</td>
										<td>
											<el-form-item class="mb0">
												{{ form.mainPartsList[i].partConsumableName || "--" }}
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												{{ form.mainPartsList[i].partConsumableQuantity || "--" }}
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												{{ form.mainPartsList[i].faultyPartSerialNumber || "--" }}
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0"> {{
													getApplicationStatusText(form.mainPartsList[i].applicationStatus) || "--"
												}}
											</el-form-item>
										</td>
									</tr>
									</tbody>
								</table>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="Advance客户编码"> {{
										form.repairAdvance.advanceCode || "--"
									}}
								</el-form-item>
								<el-form-item label="收件地址"> {{
										(form.repairAdvance.province || "") + (form.repairAdvance.city || "") + (form.repairAdvance.district || "") || "--"
									}}
								</el-form-item>
								<el-form-item label="详细地址">
									{{ form.repairAdvance.address || "--" }}
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="form-section">
					<h3> 附件 </h3>
					<div class="ml20 notice-subtitle"> 特约维修零件索赔：故障零件照片、故障照片、发票、保卡</div>
					<div class="ml20 mt5 notice-subtitle">
						特约维修主机索赔：主机整体照片、主机机号照片、故障照片、发票、保卡
					</div>
					<el-divider class="mt20"></el-divider>
					<div class="section-content">
						<!--<div class="notice-text">上传单个附件不能超过20M!</div>-->
						<div>
							<el-form-item label="维修单附件">
								<FileList :file-list="attachFileList"></FileList>
							</el-form-item>
						</div>
					</div>
				</div>
				<div class="form-section">
					<h3> 物流信息 </h3>
					<el-divider class="mt0"></el-divider>
					<div class="section-content">
						<el-row :gutter="20">
							<el-col :span="24">
								<template v-if="form.trackList">
									<table class="form-table track-fixed-table">
										<thead>
										<tr>
											<th> 序号</th>
											<th> 发货时间</th>
											<th> 快递单号</th>
											<th> 快递公司</th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="(item, i) in form.trackList">
											<td class="text-center"> {{ i + 1 }}</td>
											<td>
												<el-form-item class="mb0"> {{
														formatTimestamp(item.createTime, 'yyyy-MM-dd hh:mm:ss') || "--"
													}}
												</el-form-item>
											</td>
											<td>
												<el-form-item class="mb0"> {{
														item.trackingNumber || "--"
													}}
												</el-form-item>
											</td>
											<td>
												<el-form-item class="mb0"> {{ item.carrier || "--" }}</el-form-item>
											</td>
										</tr>
										</tbody>
									</table>
								</template>
								<template v-else>--</template>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="form-section">
					<h3> CS维修单回访 </h3>
					<el-divider class="mt0"></el-divider>
					<div class="section-content">
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="回访时间">
									{{ form.repairFollow && form.repairFollow.followUpTime || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="回访状态">
									<template v-if="form.repairFollow && form.repairFollow.followUpStatus">
										<el-tag v-for="option in followUpStatusOptions"
												v-if="form.repairFollow.followUpStatus === option.id" :key="option.id"
												:type="option.type">{{ option.name }}
										</el-tag>
									</template>
									<template v-else>--</template>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="是否发生过维修">
									<el-tag v-if="form.repairFollow && form.repairFollow.repairOccurred === 1"
											type="primary">是
									</el-tag>
									<el-tag v-else-if="form.repairFollow && form.repairFollow.repairOccurred === 0"
											type="success">否
									</el-tag>
									<template v-else>--</template>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="form-section">
					<h3> 审批进度 </h3>
					<el-divider class="mt0"></el-divider>
					<div class="section-content">
						<el-steps direction="vertical" :space="70" :active="form.claimAuditHis.length || 0">
							<el-step class="step" v-for="item in form.claimAuditHis" :key="item.auditId">
								<template slot="title">
									<div class="step-title">
										<span class="step-title-name">{{ item.createName }}</span>
										<p class="step-title-time">
											{{ formatTimestamp(item.createTime, 'YYYY-MM-DD HH:mm:ss') }}</p>
									</div>
									<div class="step-title mt5 "
										 :class="checkAuditStatus(item) ? 'step-title_red' : '' ">
										<span>{{ getAuditStatusText(item) }}</span>
									</div>
									<div class="step-remark-title mt5" v-if="item.remark">
										<span>({{ item.remark }})</span>
									</div>
								</template>
								<template slot="description" v-if="item.sysAttachFileList">
									<div class="step-description">
										<FileList :fileList="item.sysAttachFileList"></FileList>
									</div>
								</template>
							</el-step>
						</el-steps>
					</div>
				</div>
			</el-form>
		</div>
		<div>
			<el-divider></el-divider>
			<div class="action-bottom">
				<div>
					<!--维修申请-->
					<div v-if="this.$route.query.menuAction == 'repairRequest'">
						<div v-if="showAction() == '6'">
							<el-button type="primary" @click="handleApply()"> 费用申请</el-button>
						</div>
						<div v-else-if="showAction() == '2' || showAction() == '10'">
							<el-button type="primary" @click="handleRepair()"> 完成维修</el-button>
						</div>
					</div>
					<!--维修审批-->
					<div v-else-if="this.$route.query.menuAction == 'repairApproval'">
						<div v-if="showAction() == '1'">
							<el-button type="primary" @click="handleApproval(repairActionEnum.REPAIR_APPROVAL_GRANT)">
								同意
							</el-button>
							<el-button type="warning" @click="handleApproval(repairActionEnum.REPAIR_APPROVAL_RETURN)">
								退回
							</el-button>
							<el-button type="danger"
									   @click="handleApproval(repairActionEnum.REPAIR_APPROVAL_TERMINATE)"> 终止
							</el-button>
						</div>
					</div>
					<!--物流审批-->
					<div v-else-if="this.$route.query.menuAction == 'logisticsApproval'">
						<div v-if="showAction() == '3'">
							<el-button type="primary"
									   @click="handleApproval(repairActionEnum.LOGISTICS_APPROVAL_GRANT)"> 同意
							</el-button>
							<el-button type="warning"
									   @click="handleApproval(repairActionEnum.LOGISTICS_APPROVAL_RETURN)"> 退回
							</el-button>
						</div>
					</div>
					<!--索赔物流-->
					<div v-else-if="this.$route.query.menuAction == 'claimantLogistics'">
						<div v-if="showAction() == '4'">
							<el-button type="primary" @click="handleShipment()"> 发货完成</el-button>
							<el-button type="warning" @click="handleApproval(repairActionEnum.OUT_OF_STOCK)"> 缺货等待
							</el-button>
						</div>
						<div v-else-if="showAction() == '5'">
							<el-button type="primary" @click="handleShipment()"> 发货完成</el-button>
						</div>
					</div>
					<!--费用审批-->
					<div v-else-if="this.$route.query.menuAction == 'expenseApproval'">
						<div v-if="showAction() == '7'">
							<el-button type="primary" @click="handleApproval(repairActionEnum.EXPENSE_APPROVAL_GRANT)">
								通过
							</el-button>
							<el-button type="warning" @click="handleApproval(repairActionEnum.EXPENSE_APPROVAL_RETURN)">
								退回
							</el-button>
							<el-button type="danger" @click="handleApproval(repairActionEnum.EXPENSE_APPROVAL_REJECT)">
								拒绝
							</el-button>
						</div>
					</div>
					<!--回访-->
					<div v-else-if="this.$route.query.menuAction == 'followUp'">
						<div v-if="showAction() == '8'">
							<el-button type="primary" @click="handleFollowUp('add')"> 回访填写</el-button>
						</div>
						<div v-else-if="showAction() == '9' && form.repairFollow.repairOccurred == 0">
							<el-button type="primary" @click="handleFollowUp('edit')"> 编辑回访</el-button>
						</div>
					</div>
				</div>
				<el-button type="Default" class="mr15" @click="handleBack()"> 返回</el-button>
			</div>
		</div>

		<!--pdf模板-->
		<metPdf ref="metPdf"></metPdf>
		<rrtPdf ref="rrtPdf"></rrtPdf>
		<retPdf ref="retPdf"></retPdf>
		<!--同意、退回、终止-->
		<ApprovalConfirm ref="approval" @refresh="getRepairData"></ApprovalConfirm>
		<!--索赔物流-->
		<LogisticsShipment ref="shipment" @refresh="getRepairData"></LogisticsShipment>
		<!--完成维修-->
		<RepairComplete ref="repair" @refresh="getRepairData"></RepairComplete>
		<!--费用申请-->
		<ApplyConfirm ref="apply" @refresh="getRepairData"></ApplyConfirm>
		<!--回访-->
		<FollowUpConfirm ref="followup" @refresh="getRepairData"></FollowUpConfirm>
		<!--主机编辑-->
		<mainPartEdit ref="mainPart" @refresh="getRepairData"></mainPartEdit>
	</div>
</template>

<script>
import defaultSettings from "@/settings"
import {
	repairTypeOptions,
	warrantyPeriodOptions,
	serviceTypeOptions,
	repairContractTypeOptions,
	repairItemOptions,
	faultyPartCategoryOptions,
	repairAuditStatusOptions, followUpStatusOptions,
} from "@/constant/options"
import FileList from "./components/FileList.vue"
import rrtPdf from "@/components/PdfTemplate/RepairRequest/index.vue"
import metPdf from "@/components/PdfTemplate/MaintenanceReport/index.vue"
import retPdf from "@/components/PdfTemplate/ReturnExchange/index.vue"
import {repairAuditStatusEnum, repairActionEnum} from "@/constant/enum";
import ApprovalConfirm from "@/views/claimantManagement/ApprovalConfirm.vue";
import LogisticsShipment from "@/views/claimantManagement/claimantLogistics/components/LogisticsShipment.vue";
import RepairComplete from "@/views/claimantManagement/repairRequest/components/RepairComplete.vue";
import ApplyConfirm from "@/views/claimantManagement/ApplyConfirm.vue";
import FollowUpConfirm from "@/views/claimantManagement/followUp/components/FollowUpConfirm.vue";
import mainPartEdit from "@/views/claimantManagement/repairRequest/components/mainPartEdit.vue";

const url = defaultSettings.hostName

export default {
	components: {
		FollowUpConfirm,
		ApplyConfirm,
		RepairComplete,
		LogisticsShipment,
		ApprovalConfirm,
		metPdf,
		rrtPdf,
		retPdf,
		FileList,
		mainPartEdit
	},
	data() {
		return {
			dataId: "",
			form: {
				createName: "",
				createTime: "",
				serialNumber: "",               // 流水号
				repairType: "",                 // 维修区分 1、建议换货 2、维修
				dealerName: "",                 // 特约维修店名
				machineNo: "",                  // 机号
				productCode: "",                // 商品编号
				modelCode: "",                  // 机型编号
				modelName: "",                  // 机型
				outDate: "",                    // 出库日期
				warrantyEndDate: "",            // 保修期结束日
				warrantyPeriod: "",             // 1、保修中  0、非保修中
				ppsNo: "",                      // PPS号
				invoiceRequired: "",            // 是否需要上传发票 0否 1是
				repairInvoice: {
					saleDate: "",                // 销售日期
					invoiceFile: ""             // 发票附件
				},
				repairAcceptDate: "",           // 维修受理日期
				departureTime: "",              // 出发时间
				arrivalTime: "",                // 到达时间（维修开始时间）
				serviceType: "",                // 服务形式 1、送修 2、寄修 3、现场
				repairContractType: "",         // 维修合同形态 1、随时 2、维修合同 3、MC
				repairItems: "",                // 维修作业项目  维修作业项目 1、定期检查  2、修理 3、定期修理 4、继续作业 5、更换零件 6、送货 7、说明 8、合同签约 9、安装机器 10、搬回修理
				bwCounter: "",                  // 黑白计数器读数/投影机使用时间
				colorCounter: "",               // 彩色计数器读数/灯泡使用时间
				plateCounter: "",               // 制版计数器读数
				isProjector: "",                // 是否投影仪 0否 1是
				repairProjector: {
					usageHours: "",             // 使用时间
					dustFrequency: "",          // 除尘频率
					smokeEnvironment: "",       // 油烟环境
					caseEnvironment: "",        // 箱体环境
					remarks: ""                 // 其他备注
				},
				serviceFeePaid: "",             // 服务费已支付
				paymentCompany: "",             // 服务费支付公司
				paymentDate: "",                // 服务费支付日期
				expectedCompletionTime: "",     // 预计维修完成时间
				region: "",                     // 区域  1、华东 2、华南 3、华北
				receptionAttachments: "",       // 受理时随机附件 以及 送修商品外观备注
				customerCompany: "",            // 用户公司
				customerCode: "",               // 客户代码
				postalCode: "",                 // 邮政编码
				contactPerson: "",              // 送修人/保修人
				contactPhone: "",               // 联系电话
				settlementStatus: "",           // 结算状态
				provinceStr: "",                   // 省
				cityStr: "",                       // 市
				districtStr: "",                   // 区县
				addressDetail: "",              // 详细地址
				customerRepairFee: "",          // 客户应付维修费
				customerVisitFee: "",           // 客户应付上门费
				customerPartsFee: "",           // 客户应付零件费
				customerSuppliesFee: "",        // 客户应付耗材费
				customerOtherFee: "",           // 客户应付其他费用
				customerOtherFeeNotes: "",      // 客户其它费用说明
				dealerServiceFee: "",           // 特约维修服务费
				entrustServiceFee: "",          // 委托维修服务费
				otherFees: "",                  // 其他费用
				remarks: "",                    // 备注
				faultList: [
					{
						faultType: 1,           // 故障类型 1:故障现象
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					},
					{
						faultType: 2,           // 故障类型 2:故障原因
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					},
					{
						faultType: 3,           // 故障类型 3:故障措施
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					},
					{
						faultType: 4,           // 故障类型 4:故障部位
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					}
				],
				hasMainParts: "",               // 是否有主机/零件/选购件或耗材   1、有主机  2、有零件 3、没有
				canRepairWithoutParts: "",      // 是否可以在零件未到货的情况下完成维修
				mainPartsList: [],
				repairResult: {					// 维修结果
					repairOper: "",
					repairFinishedTime: "",
					pickupDate: "",
					repairStatus: 0
				},
				claimAuditHis: [], 				// 审核历史
				repairFollow: {					// CS维修单回访
					followUpTime: '',
					followUpStatus: '',
					repairOccurred: '',
				},
				trackList: [],					// 物流信息
				repairAdvance: {				// 收件地址
					advanceCode: '',
					address: '',
					province: '',
					city: '',
					district: '',
				}
			},
			mainPartStructure: {
				id:"",
				faultyPartCategory: "",         // 故障零件分类  1、主机   2、零件
				partConsumableNumber: "",       // 零件/耗材编号
				partConsumableName: "",         // 零件/耗材名称
				partConsumableQuantity: "",     // 零件/耗材数量
				faultyPartSerialNumber: "",     // 故障零件序列号码
				applicationStatus: "",           // 是否申请 0否 1是
				partConsumableNumberNew: ""
			},
			repairTypeOptions,
			warrantyPeriodOptions,
			serviceTypeOptions,
			repairContractTypeOptions,
			repairItemOptions,
			faultyPartCategoryOptions,
			repairAuditStatusOptions,
			repairActionEnum,
			followUpStatusOptions,
			applicationStatusOptions: [
				{id: 1, name: "是"},
				{id: 0, name: "否"},
			],
			address: "",
			faultDictData: {},                  // 故障相关字典数据
			invoiceFileList: [],                // 发票附件文件列表
			attachFileList: [],                 // 附件文件列表
		}
	},
	created() {
		if (this.$route.query.id) {
			this.getRepairData()
		}
		this.getFaultDictData()
	},
	computed: {
		repairTypeText() {
			for (const option of this.repairTypeOptions) {
				if (option.id === this.form.repairType || parseInt(option.id) === this.form.repairType) {
					return option.name
				}
			}
			return ""
		},
		warrantyPeriodText() {
			for (const option of this.warrantyPeriodOptions) {
				if (option.id === this.form.warrantyPeriod || parseInt(option.id) === this.form.warrantyPeriod) {
					return option.name
				}
			}
			return ""
		},
		serviceTypeText() {
			for (const option of this.serviceTypeOptions) {
				if (option.id === this.form.serviceType || parseInt(option.id) === this.form.serviceType) {
					return option.name
				}
			}
			return ""
		},
		repairContractTypeText() {
			for (const option of this.repairContractTypeOptions) {
				if (option.id === this.form.repairContractType || parseInt(option.id) === this.form.repairContractType) {
					return option.name
				}
			}
			return ""
		},
		repairItemsText() {
			for (const option of this.repairItemOptions) {
				if (option.id === this.form.repairItems || parseInt(option.id) === this.form.repairItems) {
					return option.name
				}
			}
			return ""
		},
		action() {
			return url + "bsi/sysAttachFile/addRepairFileOss"
		},
		headers() {
			const token = sessionStorage.getItem('token') || ''
			return {
				token: token
			}
		},
		faultPhen() {
			// 故障现象
			for (const item of this.form.faultList) {
				if (item.faultType === 1) {
					return item
				}
			}
		},
		faultPhenTypeOptions() {
			// 特约维修故障现象分类
			if (!this.faultDictData?.authRepFaultPhenType) return []
			return this.faultDictData?.authRepFaultPhenType
		},
		faultPhenOptions() {
			// 特约维修故障现象
			if (!this.faultDictData?.authRepFaultPhen) return []
			return this.faultDictData?.authRepFaultPhen
		},
		faultPhenTypeText() {
			if (!this.faultPhen) return ""

			for (const option of this.faultPhenTypeOptions) {
				if (option.id === this.faultPhen.category) {
					return option.name
				}
			}
			return ""
		},
		faultPhenText() {
			if (!this.faultPhen) return ""

			for (const option of this.faultPhenOptions) {
				if (option.id === this.faultPhen.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultCause() {
			// 故障原因
			for (const item of this.form.faultList) {
				if (item.faultType === 2) {
					return item
				}
			}
		},
		faultCauseTypeOptions() {
			// 特约维修故障原因分类
			if (!this.faultDictData?.authRepFaultCauseType) return []
			return this.faultDictData?.authRepFaultCauseType
		},
		faultCauseOptions() {
			// 特约维修故障原因
			if (!this.faultDictData?.authRepFaultCause) return []
			return this.faultDictData?.authRepFaultCause
		},
		faultCauseTypeText() {
			if (!this.faultCause) return ""

			for (const option of this.faultCauseTypeOptions) {
				if (option.id === this.faultCause.category) {
					return option.name
				}
			}
			return ""
		},
		faultCauseText() {
			if (!this.faultCause) return ""

			for (const option of this.faultCauseOptions) {
				if (option.id === this.faultCause.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultMeasures() {
			// 故障措施
			for (const item of this.form.faultList) {
				if (item.faultType === 3) {
					return item
				}
			}
		},
		faultMeasuresTypeOptions() {
			// 特约维修故障措施分类
			if (!this.faultDictData?.authRepFaultMeasuresType) return []
			return this.faultDictData?.authRepFaultMeasuresType
		},
		faultMeasuresOptions() {
			// 特约维修故障措施
			if (!this.faultDictData?.authRepFaultMeasures) return []
			return this.faultDictData?.authRepFaultMeasures
		},
		faultMeasuresTypeText() {
			if (!this.faultMeasures) return ""

			for (const option of this.faultMeasuresTypeOptions) {
				if (option.id === this.faultMeasures.category) {
					return option.name
				}
			}
			return ""
		},
		faultMeasuresText() {
			if (!this.faultMeasures) return ""

			for (const option of this.faultMeasuresOptions) {
				if (option.id === this.faultMeasures.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultPosition() {
			// 故障部位
			for (const item of this.form.faultList) {
				if (item.faultType === 4) {
					return item
				}
			}
		},
		faultPositionTypeOptions() {
			// 特约维修故障部位分类
			if (!this.faultDictData?.authRepFaultPositionType) return []
			return this.faultDictData?.authRepFaultPositionType
		},
		faultPositionOptions() {
			// 特约维修故障部位
			if (!this.faultDictData?.authRepFaultPosition) return []
			return this.faultDictData?.authRepFaultPosition
		},
		faultPositionTypeText() {
			if (!this.faultPosition) return ""

			for (const option of this.faultPositionTypeOptions) {
				if (option.id === this.faultPosition.category) {
					return option.name
				}
			}
			return ""
		},
		faultPositionText() {
			if (!this.faultPosition) return ""

			for (const option of this.faultPositionOptions) {
				if (option.id === this.faultPosition.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
	},
	methods: {
		formatTimestamp(ms, datetime) {
			const _fmt = datetime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
		// 审核状态
		getAuditStatusText(row) {
			if (row?.auditStatus) {
				for (const option of this.repairAuditStatusOptions) {
					if (option.id === row.auditStatus) {
						return option.name
					}
				}
			}
			return "待提交"
		},
		checkAuditStatus(row) {
			if (row?.auditStatus) {
				if ([-2, -3, -4, -5].includes(row.auditStatus)) {
					return true
				}
			}
			return false
		},
		formatPriceString(price, unit) {
			if (price === null || price === undefined) return ""
			if (typeof price === "string" && price.trim() === "") return ""
			try {
				const p = parseInt(price)
				if (isNaN(p)) return price
				let s = p.toLocaleString("zh-CN", {style: 'currency', currency: 'CNY'})
				return `${s}${unit || ""}`
			} catch (e) {
				return price
			}
		},
		getFaultyPartCategoryText(id) {
			for (const option of this.faultyPartCategoryOptions) {
				if (option.id === id) {
					return option.name
				}
			}
			return ""
		},
		getApplicationStatusText(id) {
			for (const option of this.applicationStatusOptions) {
				if (option.id === id) {
					return option.name
				}
			}
			return ""
		},
		getFaultDictData() {
			// 获取故障相关的字典数据
			let that = this
			const params = {
				"codes": "authRepFaultCause" +
					",authRepFaultCauseType" +
					",authRepFaultMeasures" +
					",authRepFaultMeasuresType" +
					",authRepFaultPhen" +
					",authRepFaultPhenType" +
					",authRepFaultPosition" +
					",authRepFaultPositionType"
			}
			that.$request.post(
				"getListByCodes",
				false,
				params,
				function (r) {
					if (r.code == "0") {
						that.faultDictData = r.data
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		getUrlFileName(url) {
			if (!url) return ""
			return url.substring(url.lastIndexOf("/") + 1)
		},
		getFinishedFile(file) {
			return {
				id: file.id,
				name: file.fileName,
				url: file.fileAddr,
				size: file.fileSize,
				status: 'finished', // 文件上传状态，可以是 'uploading', 'finished', 'error'
				response: {} // 服务器响应
			}
		},
		getRepairData() {
			if (!this.$route.query.id) return

			let that = this
			that.$request.get(
				"repairRequestInfo",
				true,
				{
					id: this.$route.query.id,
				},
				function (r) {
					if (r.code == "0") {
						that.dataId = r.data.id
						that.restoreFrom(r.data)
						that.getRepairAuditHis()
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		// 获取维修审核历史
		getRepairAuditHis() {
			if (!this.$route.query.id) return

			let that = this
			that.$request.post(
				"claimAuditHis",
				true,
				{
					relatedId: this.$route.query.id,
				},
				function (r) {
					if (r.code == "0") {
						that.form.claimAuditHis = r.data
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		restoreFrom(data) {
			// 遍历设置所有非数组属性
			this.traverseSet(this.form, data)

			// faultList
			if (data.faultList) {
				for (let i in this.form.faultList) {
					if (data.faultList[i]) {
						for (const attr in this.form.faultList[i]) {
							this.$set(this.form.faultList[i], attr, data.faultList[i][attr])
						}
					}
				}
			}

			// mainPartsList
			this.form.mainPartsList = []
			if (data.mainPartsList) {
				for (let i in data.mainPartsList) {
					const mainPart = JSON.parse(JSON.stringify(this.mainPartStructure))
					for (const attr in mainPart) {
						mainPart[attr] = data.mainPartsList[i][attr]
					}
					this.form.mainPartsList.push(mainPart)
				}
			}

			// 附件
			this.invoiceFileList = [
				{
					name: this.getUrlFileName(this.form.repairInvoice.invoiceFile),
					url: this.form.repairInvoice.invoiceFile,
					status: 'finished', // 文件上传状态，可以是 'uploading', 'finished', 'error'
					response: {} // 服务器响应
				}
			]
			const files = data.sysAttachFileList || []
			this.attachFileList = files.map(item => {
				return this.getFinishedFile(item)
			})

			// fileIdList
			this.form.fileIdList = files.map(item => {
				return item.id
			})

			this.address = `${data.provinceStr || ""}${data.cityStr}${data.districtStr}`

			// 物流信息
			this.form.trackList = data.trackList || [];
		},
		traverseSet(form, data) {
			// 遍历设置除数组属性的所有值
			for (const attr in form) {
				if (typeof form[attr] === "object") {
					if (!Array.isArray(form[attr])) {
						this.traverseSet(form[attr], data[attr])
					}
				} else {
					if (data) this.$set(form, attr, data[attr] ?? "")
				}
			}
		},
		// pdf模板选择
		handleCommand(value) {
			if (value === 'default') {
				// 默认模板
				const newForm = {
					...this.form,
					...this.faultDictData,
					sysAttachFileList: this.attachFileList,
				}
				if (!newForm) {
					console.error("默认模板 data is undefined");
					return;
				}
				this.$refs.rrtPdf.init(newForm)
			} else if (value === 'refund') {
				// 退换机申请书
				const newForm = {
					...this.form,
					...this.faultDictData
				}
				if (!newForm) {
					console.error("退换机申请书 data is undefined");
					return;
				}
				this.$refs.retPdf.init(newForm)
			} else if (value === 'maintenanceReport') {
				// 理光特约维修店维修报告
				const newForm = {
					...this.form,
					...this.faultDictData
				}
				if (!newForm) {
					console.error("理光特约维修店维修报告 data is undefined");
					return;
				}
				this.$refs.metPdf.init(newForm)
			} else if (value === 'maintenanceReportBlank') {
				// 理光特约维修店维修报告（空白）
				this.$refs.metPdf.init()
			}
		},
		// 审批
		handleApproval(action) {
			const newForm = {
				...this.form,
				relatedId: this.dataId
			}
			this.$refs.approval.show(action, newForm)
		},
		showEdit() {
			return !(this.$route.query.menuAction == 'repairApproval' || this.$route.query.menuAction == 'logisticsApproval');
		},
		// 操作按钮状态
		showAction() {
			if (this.form.claimAuditHis && this.form.claimAuditHis.length <= 0) {
				return "0"
			}

			const auditStatus = this.form.claimAuditHis[this.form.claimAuditHis.length - 1]?.auditStatus
			if (auditStatus == repairAuditStatusEnum.SUBMITTED || auditStatus == repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED_SUBMIT || auditStatus == repairAuditStatusEnum.LOGISTICS_APPROVAL_RETURNED) {
				return "1"
			} else if (auditStatus == repairAuditStatusEnum.SHIPMENT_COMPLETED) {
				return "2"
			} else if (auditStatus == repairAuditStatusEnum.REPAIR_APPROVAL_GRANTED || auditStatus == repairAuditStatusEnum.LOGISTICS_APPROVAL_RETURNED_SUBMIT) {
				// hasMainParts === 3 增加 没有 主机/零件/选购件或耗材
				return this.form.hasMainParts === 3 ? "10" : "3"
			} else if (auditStatus == repairAuditStatusEnum.LOGISTICS_APPROVAL_GRANTED) {
				return "4"
			} else if (auditStatus == repairAuditStatusEnum.OUT_OF_STOCK) {
				return "5"
			} else if (auditStatus == repairAuditStatusEnum.REPAIR_COMPLETED || auditStatus == repairAuditStatusEnum.EXPENSE_APPROVAL_RETURNED) {
				return "6"
			} else if (auditStatus == repairAuditStatusEnum.EXPENSE_REQUESTED || auditStatus == repairAuditStatusEnum.COST_APPROVAL_RETURNED_SUBMIT || auditStatus == repairAuditStatusEnum.COST_APPROVAL_REJECTED_SUBMIT) {
				return "7"
			} else if (auditStatus == repairAuditStatusEnum.EXPENSE_APPROVAL_GRANTED) {
				return "8"
			} else if (auditStatus == repairAuditStatusEnum.FOLLOW_UP_AGREED) {
				return "9"
			} else {
				return '0'
			}
		},
		// 返回
		handleBack() {
			this.$router.go(-1)
		},
		// 发货完成
		handleShipment() {
			const newForm = {
				...this.form,
				relatedId: this.dataId
			}
			this.$refs.shipment.show(newForm)
		},
		handleRepair() {
			const newForm = {
				...this.form,
				relatedId: this.dataId
			}
			this.$refs.repair.show(newForm)
		},
		// 费用申请
		handleApply() {
			const newForm = {
				...this.form,
				relatedId: this.dataId
			}
			this.$refs.apply.show('', newForm)
		},
		// 回访
		handleFollowUp(type) {
			const newForm = {
				relatedId: this.dataId,
				repairOccurred: this.form.repairFollow.repairOccurred,
				followUpTime: this.form.repairFollow.followUpTime,
				followUpStatus: this.form.repairFollow.followUpStatus
			}
			this.$refs.followup.show(type, newForm)
		},
		handleEdit(row) {
			this.$refs.mainPart.show('', row)
		},
	}
}
</script>

<style scoped lang="scss">
.form-section {
	padding-bottom: 20px;
	border: 1px solid rgb(174, 141, 90);
	border-radius: 8px;

	h3 {
		height: 60px;
		line-height: 60px;
		padding: 0 20px;
	}

	.section-content {
		padding: 0 20px;

		.step {
			height: 200px;

			.step-title {
				display: flex;
				color: #67c23a;

				.step-title-time {
					margin-left: 18px;
					color: #999999;
					font-size: 12px;
				}
			}

			.step-title_red {
				color: red;
			}

			.step-remark-title {
				color: #999999;
				font-size: 14px;
			}

			.step-title-name {
				color: black;
			}

			.step-description {
				height: 100%;
				width: 300px;
			}
		}
	}

	.section-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.submit {
			margin-right: 20px;
			height: 32px;
			border: none;
			color: rgba(255, 255, 255, 1);
			background-color: rgba(17, 127, 252, 1);
		}
	}
}

.section-title {
	color: #999999;
	padding-bottom: 18px;
}

.form-table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;

	td {
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	thead {
		background-color: rgb(236, 242, 240);

		tr {
			text-align: left;

			th {
				padding: 18px 10px;

				&.col-required {
					&:before {
						content: '*';
						color: #F56C6C;
						margin-right: 4px;
					}
				}
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 10px;
				vertical-align: top;
			}
		}
	}
}

.fixed-table {
	th:first-child, td:first-child {
		position: sticky;
		right: 0;
		z-index: 1;
	}

	th {
		&:nth-child(1) {
			width: 40px;
		}

		&:nth-child(2) {
			width: 140px;
		}

		&:nth-child(5) {
			width: 140px;
		}

		&:nth-child(7) {
			width: 100px;
		}

		&:nth-child(8) {
			width: 50px;
		}
	}

	td {
		vertical-align: middle !important;
		word-wrap: break-word;
		word-break: break-all;
		white-space: normal;
	}
}

.track-fixed-table {
	th:first-child, td:first-child {
		position: sticky;
		right: 0;
		z-index: 1;
	}

	th {
		&:nth-child(1) {
			width: 40px;
		}

	}

	td {
		vertical-align: middle !important;
	}
}

.text-center {
	text-align: center;
}

.notice-text {
	color: rgb(204, 80, 80);
	margin-bottom: 30px;
}

.notice-subtitle {
	font-size: 14px;
	color: #606266;
}

.fixed-wrapper {
	position: fixed;
	right: 12%;
	bottom: 200px;
}

.btn-red {
	color: #E9515E;
	text-decoration: underline;
	cursor: pointer;
}

.action-bottom {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
</style>
