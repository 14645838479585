<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="true"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="data-dialog"
		width="1200px">
		<button class="el-button down-btn menu-btn el-button--primary" @click="handlePdf()">下载</button>
		<div class="mrt-content" ref="pdfcontent">
			<h1>退/换机申请书</h1>
			<table>
				<tr>
					<th>PPS号</th>
					<th colspan="2">{{ obj.ppsNo || "" }}</th>
					<th>发行No</th>
					<th colspan="2">{{ obj.serialNumber || "" }}</th>
				</tr>
				<tr>
					<th>区域</th>
					<th colspan="2">[]华北[]华东[]华南[]成都</th>
					<th>类别</th>
					<th colspan="2">[]直销[]代销</th>
				</tr>
				<tr>
					<th>申请公司</th>
					<th colspan="2">{{ obj.customerCompany || '' }}</th>
					<th>公司地址</th>
					<th colspan="2">
						{{
							(obj.provinceStr || "") + (obj.cityStr || "") + (obj.districtStr || "") + (obj.addressDetail || "")
						}}
					</th>
				</tr>
				<tr>
					<th>申请人</th>
					<th colspan="2">{{ obj.contactPerson || "" }}</th>
					<th>联系电话</th>
					<th colspan="2">{{ obj.contactPhone || "" }}</th>
				</tr>
				<tr>
					<th>最终客户名称</th>
					<th colspan="2">{{ obj.customerCompany || '' }}</th>
					<th>销售店名称（服务实施店）</th>
					<th colspan="2">{{ obj.dealerName || "" }}</th>
				</tr>
				<tr>
					<th>开始使用日</th>
					<th colspan="2"></th>
					<th>部门申请日</th>
					<th colspan="2"></th>
				</tr>
				<tr>
					<th>申请日</th>
					<th colspan="2">{{ formatTimestamp(obj.createTime) }}</th>
					<th>使用张数</th>
					<th colspan="2"></th>
				</tr>
				<tr>
					<th>退换机原因</th>
					<th colspan="5">[]运作不良[]开箱不良[]运输损坏[]其他</th>
				</tr>
				<tr>
					<th>EDP</th>
					<th>商品名称</th>
					<th>数量</th>
					<th>机身号码</th>
					<th colspan="2">其他相关情况</th>
				</tr>
				<tr v-for="(item, i) in obj.mainPartsList">
					<th>{{ item.partConsumableNumber || "" }}</th>
					<th>{{ item.partConsumableName || "" }}</th>
					<th>{{ item.partConsumableQuantity || "" }}</th>
					<th>{{ item.faultyPartSerialNumber || "" }}</th>
					<th colspan="2">[]说明书[]耗材未使用[]内外包装良好[]保修卡未填写(代销)</th>
				</tr>
				<tr>
					<th>货品存放地</th>
					<th></th>
					<th>联系人</th>
					<th></th>
					<th>联系电话</th>
					<th></th>
				</tr>
				<tr>
					<th>申请理由</th>
					<th colspan="2">*决定换机的主要理由？</th>
					<th colspan="3">*采取过何种对策？</th>
				</tr>
				<tr class="tr-height240">
					<td>受理窗口填写</td>
					<td colspan="2"></td>
					<td colspan="3"></td>
				</tr>
				<tr>
					<th>建议退换货部门</th>
					<th colspan="5">[]销售部门[]技术部门处</th>
				</tr>
				<tr>
					<th colspan="6" class="th-left">处理方法</th>
				</tr>
				<tr>
					<th>[]机器交换</th>
					<th></th>
					<th>新品收获方</th>
					<th colspan="3"></th>
				</tr>
				<tr>
					<th>[]红票处理</th>
					<th></th>
					<th>收货地点</th>
					<th colspan="3"></th>
				</tr>
				<tr>
					<th>[]其他</th>
					<th></th>
					<th>联系人</th>
					<th></th>
					<th>联系电话</th>
					<th></th>
				</tr>
				<tr>
					<th>销售部门签字</th>
					<th>技术部门签字</th>
					<th colspan="4" class="th-left">最终判定部门：SCM推进课</th>
				</tr>
				<tr class="tr-height160">
					<th></th>
					<th></th>
					<th colspan="4" class="th-left">处理意见：<br>[]退回不良品，为客户补发新品<br>[]退回不良品，客户退货不发新品<br>[]其他
					</th>
				</tr>
			</table>
			<div class="footer-left-datetime">
				{{ dateTime }}
			</div>
		</div>
	</el-dialog>
</template>
<script>
import JsPDF from 'jspdf'
import html2Canvas from "html2canvas";

export default {
	data() {
		return {
			visible: false,
			obj: {},
			dateTime: '',
		}
	},
	mounted() {
		this.dateTime = this.formatDateTime(new Date())
	},
	methods: {
		init(data) {
			this.visible = true;
			this.$nextTick(() => {
				setTimeout(() => {
					this.obj = {
						...data,
					};
				}, 50)
			})
		},
		// pdf下载
		handlePdf() {
			const title = "退/换机申请书_" + new Date().getTime();
			const opts = {
				scale: window.devicePixelRatio * 3,
				dpi: window.devicePixelRatio * 4,
				allowTaint: true,
				useCORS: true
			};
			let that = this;
			html2Canvas(this.$refs.pdfcontent, opts).then(function (canvas) {
				let imgWidth = 595.28
				let imgHeight = 860
				let pageData = canvas.toDataURL('image/jpeg', 1.0)
				let PDF = new JsPDF('', 'pt', 'a4')
				PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
				PDF.save(title + '.pdf')
				that.visible = false;
			})
		},
		// 格式化日期时间
		formatDateTime(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			return `${year}-${month}-${day} ${hours}:${minutes}`;
		},
		formatTimestamp(ms, datetime) {
			const _fmt = datetime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
	}
}
</script>
<style lang="scss" scoped>

.data-dialog /deep/ .el-dialog {
	border-radius: 10px !important;
}

.data-dialog /deep/ .el-dialog__body {
	padding: 0 20px !important;
}

.mrt-content {
	padding: 80px 60px;
	background-color: white;
	color: black;

	h1 {
		font-size: 28px;
		font-weight: normal;
		text-align: center;
		margin-bottom: 15px;
		color: black;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		font-size: 16px;
		font-weight: normal;
		color: black;
		margin-bottom: 110px;
		table-layout: fixed;
	}

	table, th, td {
		border: 1.1px solid black;
	}

	th, td {
		font-weight: normal;
		padding: 6px;
		background-color: white;
		text-align: center;
		white-space: normal;
		word-wrap: break-word;
	}

	tr {
		height: 40px;
	}

	td {
		width: 150px;
	}

	.th-left {
		vertical-align: top;
		text-align: left;
		padding-left: 2ch;
	}

	.tr-height {
		height: 80px;
	}

	.tr-height160 {
		height: 160px;
	}

	.tr-height240 {
		height: 240px;
	}

	.footer-left-datetime {
		font-size: 16px;
		font-weight: normal;
		color: black;
		margin-bottom: 20px;
	}
}

</style>
