<template>
	<el-dialog
		ref="auditDialog"
		:title="title"
		:visible.sync="visible"
		width="40%"
	>
		<el-form ref="auditForm" :model="form" :rules="rules" label-position="top">
			<el-form-item :label="remarkLabel" prop="remark">
				<el-input v-model="form.remark" type="textarea" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="维修报告" prop="fileIdList">
				<el-upload
					:action="uploadAction"
					:headers="uploadHeaders"
					:multiple="true"
					:show-file-list="true"
					:file-list="attachFileList"
					:limit="fileLimit"
					:before-upload="handleBeforeUpload"
					:on-success="handleFileUploaded"
					:on-remove="handleFileRemove"
				>
					<el-button icon="el-icon-paperclip" :disabled="attachFileList.length >= fileLimit">上传文件</el-button>
					<div slot="tip" class="el-upload__tip">(最多可上传{{ fileLimit }}个, 每个不超过20M)</div>
				</el-upload>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel"> 取消 </el-button>
            <el-button type="primary" :loading="loading" @click="handleApproval"> 确定 </el-button>
        </span>
	</el-dialog>
</template>

<script>
import defaultSettings from "@/settings"
import { debounce } from '@/utils/debounce'
import { repairActionEnum, repairAuditStatusEnum } from "@/constant/enum"

const url = defaultSettings.hostName

export default {
	name: 'ApplyConfirm',
	props: { },
	data() {
		return {
			visible: false,
			loading: false,
			action: "",
			form: {
				relatedId: '',      // 维修申请数据id
				auditStatus: '',    // 审核状态
				fileIdList: [],     // 附件
				remark: '费用申请',         // 审批意见
				extAttr: '',        // 扩展字段
			},
			attachFileList: [],
			advanceData: null,
			rules: {
				remark: [
					{ required: true, message: "请输入意见", trigger: "blur" },
				],
				fileIdList: [
					{ required: true, message: "请上传附件", trigger: "change" },
				]
			},
			extAttrRule: { required: false, message: "请输入", trigger: "blur" },
			repairActionEnum,
			repairAuditStatusEnum,
		}
	},
	computed: {
		title() {
			return "费用申请"
		},
		remarkLabel() {
			return "申请原因"
		},
		fileLimit() {
			// 最大上传文件数
			return 5
		},
		uploadAction() {
			return url + "bsi/sysAttachFile/addRepairFileOss"
		},
		uploadHeaders() {
			const token = sessionStorage.getItem('token') || ''
			return {
				token: token
			}
		},
		area() {
			if (this.advanceData) {
				const province = this.advanceData.province || ''
				const city = this.advanceData.city || ''
				return `${province}${city}`
			}
			return ''
		},
		address() {
			return this.advanceData?.address || ''
		},
	},
	methods: {
		show(action, data) {
			this.action = action

			this.loading = false
			this.$refs.auditForm && this.$refs.auditForm.resetFields()
			this.$set(this, "attachFileList", [])
			this.form.relatedId = data.relatedId
			this.$nextTick(() => { this.visible = true })
		},
		handleCancel() {
			this.visible = false
		},
		setFormStatus() {
			// 费用申请
			this.form.auditStatus = 7
		},
		handleApproval() {
			this.setFormStatus()

			// if (!this.advanceData) {
			//     this.$message.error('请输入合法的Advance客户编码')
			//     return
			// }

			let that = this
			that.$refs['auditForm'].validate((valid, fields) => {
				if (!valid) {
					return
				}

				that.loading = true
				that.$request.post(
					"claimAuditAdd",
					false,
					that.form,
					function (r) {
						if (r.code == "0") {
							that.visible = false
							that.$emit("refresh")
						} else {
							that.loading = false
							that.$message.error(r.msg)
						}
					},
					function () {
						that.loading = false
					}
				)
			})
		},
		handleBeforeUpload(file) {
			// 上传前检查
			const limitSize = file.size / 1024 / 1024 <= 20
			if (!limitSize) {
				this.$message.warning('附件大小不能超过20M')
				return false
			}
		},
		// 上传附件成功
		handleFileUploaded(response, file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
		handleFileRemove(file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
		getAdvanceInfo: debounce(function () {
			if (!this.form.extAttr.trim()) {
				this.advanceData = null
				return
			}

			let that = this
			that.$request.get(
				"getAdvanceData",
				false,
				{ advanceCode: this.form.extAttr },
				function (r) {
					if (r.code == "0") {
						that.advanceData = r.data
					} else {
						that.advanceData = null
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		}, 500),
	}
}
</script>

<style scoped lang="scss">

</style>
