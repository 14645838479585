<template>
    <el-dialog
        title="完成维修"
        :visible.sync="visible"
        width="650px"
    >
        <el-form ref="form" :model="form" label-position="left" label-width="140px">
            <el-form-item label="修理完成日期" prop="repairResult.repairFinishedTime" :rules="repairResultRules.repairFinishedTime">
                <el-date-picker
                    v-model="form.repairResult.repairFinishedTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择"
                    class="full-width"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="用户取回机器日期" prop="repairResult.pickupDate" :rules="repairResultRules.pickupDate">
                <el-date-picker
                    v-model="form.repairResult.pickupDate"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择"
                    class="full-width"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="维修人姓名" prop="repairResult.repairOper" :rules="repairResultRules.repairOper">
                <el-input v-model="form.repairResult.repairOper" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="维修结果" prop="repairResult.repairStatus" :rules="repairResultRules.repairStatus">
                <el-radio-group v-model="form.repairResult.repairStatus">
                    <el-radio :label="1">
                        <el-tag type="primary">已修复</el-tag>
                    </el-radio>
                    <el-radio :label="2">
                        <el-tag type="warning">其他</el-tag>
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCancel"> 取消 </el-button>
            <el-button type="primary" :loading="loading" @click="handleApproval"> 确认 </el-button>
        </span>
    </el-dialog>
</template>

<script>
import { repairActionEnum, repairAuditStatusEnum } from "@/constant/enum"

export default {
    name: 'RepairComplete',
    props: { },
    data() {
        return {
            visible: false,
            loading: false,
            form: {
                id: "",
                repairResult: {
                    repairFinishedTime: "",     // 修理完成日期
                    pickupDate: "",             // 用户取回机器日期
                    repairOper: "",             // 维修人姓名
                    repairStatus: "",           // 1：已修复，2：其它
                }
            },
            repairResultRules: {
                repairFinishedTime: [
                    { required: true, message: "请选择修理完成日期", trigger: "change" },
                ],
                pickupDate: [
                    { required: true, message: "请选择用户取回机器日期", trigger: "change" },
                ],
                repairOper: [
                    { required: true, message: "请输入维修人姓名", trigger: "blur" },
                ],
                repairStatus: [
                    { required: true, message: "请选择维修结果", trigger: "change" },
                ],
            },
            repairActionEnum,
            repairAuditStatusEnum,
        }
    },
    methods: {
        show(data) {
            this.loading = false
            this.$refs.form && this.$refs.form.resetFields()
            this.form.id = data.relatedId
            this.$nextTick(() => { this.visible = true })
        },
        handleCancel() {
            this.visible = false
        },
        handleApproval() {
            let that = this
            that.$refs['form'].validate((valid, fields) => {
                if (!valid) {
                    return
                }

                that.loading = true

                that.$request.post(
                    "claimAuthRepairOrderFinish",
                    false,
                    that.form,
                    function (r) {
                        if (r.code == "0") {
                            that.visible = false
                            that.$emit("refresh")
                        } else {
                            that.$message.error(r.msg)
                        }
                    },
                    function () {
                    }
                )
            })
        },
    }
}
</script>

<style scoped lang="scss">

</style>
