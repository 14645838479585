<template>
	<el-dialog
		ref="editDialog"
		title="零件编号商品变更"
		:visible.sync="visible"
		width="40%"
	>
		<el-form ref="editForm"
				 :model="form"
				 :rules="rules"
				 label-position="top">
			<el-form-item label="零件编号商品"
						  prop="partConsumableNumberNew">
				<el-input v-model="form.partConsumableNumberNew"
						  type="input"
						  placeholder="请输入"></el-input>
			</el-form-item>
		</el-form>

		<span slot="footer"
			  class="dialog-footer">
            <el-button @click="handleCancel"> 取消 </el-button>
            <el-button type="primary"
					   :loading="loading"
					   @click="handleApproval"> 确定 </el-button>
        </span>
	</el-dialog>
</template>

<script>
import defaultSettings from "@/settings"
import {debounce} from '@/utils/debounce'

const url = defaultSettings.hostName

export default {
	name: 'mainPartEdit',
	props: {},
	data() {
		return {
			visible: false,
			loading: false,
			form: {
				id: '',
				partConsumableNumberNew: ''
			},
			attachFileList: [],
			advanceData: null,
			rules: {
				partConsumableNumberNew: [
					{required: true, message: "请输入", trigger: "blur"},
				]
			},
		}
	},
	methods: {
		show(action, data) {
			this.action = action
			this.loading = false
			this.$refs.editForm && this.$refs.editForm.resetFields()
			this.form.id = data.id
			this.form.partConsumableNumberNew = data.partConsumableNumberNew
			this.$nextTick(() => {
				this.visible = true
			})
		},
		handleCancel() {
			this.visible = false
		},
		handleApproval() {
			let that = this
			that.$refs['editForm'].validate((valid, fields) => {
				if (!valid) {
					return
				}

				that.loading = true
				that.$request.post(
					"claimAuthMainPartsUpdate",
					false,
					that.form,
					function (r) {
						if (r.code == "0") {
							that.visible = false
							that.$emit("refresh")
						} else {
							that.loading = false
							that.$message.error(r.msg)
						}
					},
					function () {
						that.loading = false
					}
				)
			})
		},
		handleBeforeUpload(file) {
			// 上传前检查
			const limitSize = file.size / 1024 / 1024 <= 20
			if (!limitSize) {
				this.$message.warning('附件大小不能超过20M')
				return false
			}
		},
		// 上传附件成功
		handleFileUploaded(response, file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
		handleFileRemove(file, fileList) {
			this.attachFileList = fileList
			this.form.fileIdList = fileList.map(item => item.response.data.id)
		},
		getAdvanceInfo: debounce(function () {
			if (!this.form.extAttr.trim()) {
				this.advanceData = null
				return
			}

			let that = this
			that.$request.get(
				"getAdvanceData",
				false,
				{advanceCode: this.form.extAttr},
				function (r) {
					if (r.code == "0") {
						that.advanceData = r.data
					} else {
						that.advanceData = null
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		}, 500),
	}
}
</script>

<style scoped
	   lang="scss">

</style>
