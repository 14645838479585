<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="data-dialog"
		width="1200px">
		<span slot="title" class="dialog-title">
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<button class="el-button down-btn menu-btn el-button--primary" @click="handlePdf()">下载</button>
		<div class="mrt-content" ref="pdfcontent">
			<h1>维修申请-填写页</h1>
			<div class="head mb20">
				<p>发起人 {{ this.obj.createName || "" }}</p>
				<div class="sub-title">
					<p>创建时间{{ dateTime }} </p>
					<p>流水号 {{ obj.serialNumber }}</p>
				</div>
			</div>
			<table>
				<tr v-for="item in repairData" :key="item.field">
					<td>{{ item.field }}</td>
					<td>{{ item.value }}</td>
				</tr>
			</table>

			<!--故障现象-->
			<div class="head">
				<div>故障现象</div>
			</div>
			<table style="width:75%;">
				<tr>
					<th>分类</th>
					<th>现象</th>
					<th>详细说明</th>
				</tr>
				<tr>
					<th>{{ faultPhenTypeText || "" }}</th>
					<th>{{ faultPhenText || "" }}</th>
					<th>{{ faultPhen && faultPhen.remark || "" }}</th>
				</tr>
			</table>

			<!--故障原因-->
			<div class="head">
				<div>故障原因</div>
			</div>
			<table style="width:75%;">
				<tr>
					<th>分类</th>
					<th>现象</th>
					<th>详细说明</th>
				</tr>
				<tr>
					<th>{{ faultCauseTypeText || "" }}</th>
					<th>{{ faultCauseText || "" }}</th>
					<th>{{ faultCause && faultCause.remark || "" }}</th>
				</tr>
			</table>

			<!--故障措施-->
			<div class="head">
				<div>故障措施</div>
			</div>
			<table style="width:75%;">
				<tr>
					<th>分类</th>
					<th>现象</th>
					<th>详细说明</th>
				</tr>
				<tr>
					<th>{{ faultMeasuresTypeText || "" }}</th>
					<th>{{ faultMeasuresText || "" }}</th>
					<th>{{ faultMeasures && faultMeasures.remark || "" }}</th>
				</tr>
			</table>

			<!--故障部位-->
			<div class="head">
				<div>故障部位</div>
			</div>
			<table style="width:75%;">
				<tr>
					<th>分类</th>
					<th>现象</th>
					<th>详细说明</th>
				</tr>
				<tr>
					<th>{{ faultPositionTypeText || "" }}</th>
					<th>{{ faultPositionText || "" }}</th>
					<th>{{ faultPosition && faultPosition.remark || "" }}</th>
				</tr>
			</table>

			<!--主机/零件/选购件或耗材-->
			<div class="head">
				<div>主机/零件/选购件或耗材</div>
			</div>
			<table>
				<tr>
					<th>故障零件分类</th>
					<th>零件/耗材编号</th>
					<th>零件编号商品变更</th>
					<th>零件/耗材名称</th>
					<th>零件/耗材数量</th>
					<th>故障零件序列号码</th>
					<th>是否申请</th>
				</tr>
				<tr v-for="(item, i) in obj.mainPartsList">
					<th>{{ getFaultyPartCategoryText(item.faultyPartCategory) || "" }}</th>
					<th>{{ item.partConsumableNumber || "" }}</th>
					<th></th>
					<th>{{ item.partConsumableName || "" }}</th>
					<th>{{ item.partConsumableQuantity || "" }}</th>
					<th>{{ item.faultyPartSerialNumber || "" }}</th>
					<th>{{ getApplicationStatusText(item.applicationStatus) || "" }}</th>
				</tr>
			</table>

			<!--快递单号-->
			<div class="head">
				<div>快递单号</div>
			</div>
			<table style="width:30%;">
				<tr>
					<th>快递单号</th>
				</tr>
				<tr v-for="(item, i) in obj.trackList">
					<th>{{ item.trackingNumber || "" }}</th>
				</tr>
			</table>

			<!--审批记录-->
			<div class="head">
				<div>审批记录</div>
			</div>
			<table>
				<tr>
					<th style="width: 100px;">审批人</th>
					<th style="width: 160px;">审批时间</th>
					<th>审批结果</th>
					<th>审批意见</th>
					<th>审批附件</th>
					<th style="width: 100px;">节点名称</th>
				</tr>
				<tr v-for="(record, index) in obj.claimAuditHis" :key="index">
					<td>{{ record.createName }}</td>
					<td>{{ formatTimestamp(record.createTime, 'YYYY-MM-DD HH:mm:ss') }}</td>
					<td>{{ getAuditStatusText(record) }}</td>
					<td>{{ record.remark || "" }}</td>
					<td>{{ record.sysAttachFileList && getAttachFileText(record.sysAttachFileList) }}</td>
					<td>{{ record.nodeName }}</td>
				</tr>
			</table>

		</div>
		<div
			class="pdf-header"
			style="
          font-weight: bold;
          padding: 30px 8px;
          width: 1200px;
          color: rgba(0, 0, 0, 0.85);
          position: fixed;
          top: -100vh;
          color: white;
        "
		>页眉
		</div>
		<div
			class="pdf-footer"
			style="
          font-weight: bold;
          padding: 40px 8px;
          width: 100%;
          border-top: 1px solid rgba(0, 0, 0, 0.85);
          position: fixed;
          top: -100vh;
        "
		>
			<div
				style="
            display: flex;
            font-weight: bold;
            justify-content: center;
            align-items: center;
            padding-top: 5px;
            color: white;
          "
			>
				我是页尾
			</div>
			<div
				style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-size: 20px;
          "
			>
				第
				<div class="pdf-footer-page"></div>
				页 / 共
				<div class="pdf-footer-page-count"></div>
				页
			</div>
		</div>
	</el-dialog>
</template>
<script>
import {PdfLoader} from "@/utils/pdfLoader";
import {
	repairTypeOptions,
	warrantyPeriodOptions,
	serviceTypeOptions,
	faultyPartCategoryOptions,
	repairAuditStatusOptions,
	repairContractTypeOptions,
	repairItemOptions,
	followUpStatusOptions
} from "@/constant/options"

export default {
	data() {
		return {
			repairTypeOptions,
			warrantyPeriodOptions,
			serviceTypeOptions,
			faultyPartCategoryOptions,
			repairAuditStatusOptions,
			repairContractTypeOptions,
			repairItemOptions,
			followUpStatusOptions,
			visible: false,
			obj: {
				faultList: [],
				claimAuditHis: [],
				sysAttachFileList: []
			},
			dateTime: '',
			applicationStatusOptions: [
				{id: 1, name: "是"},
				{id: 0, name: "否"},
			],
			repairData: []
		}
	},
	mounted() {
		this.dateTime = this.formatDateTime(new Date())
	},
	computed: {
		faultPhen() {
			if (!this.obj.faultList || !Array.isArray(this.obj.faultList)) {
				return null;
			}
			// 故障现象
			for (const item of this.obj.faultList) {
				if (item.faultType === 1) {
					return item
				}
			}
		},
		faultPhenText() {
			if (!this.faultPhen) return ""

			for (const option of this.faultPhenOptions) {
				if (option.id === this.faultPhen.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultPhenTypeOptions() {
			// 特约维修故障现象分类
			if (!this.obj?.authRepFaultPhenType) return []
			return this.obj?.authRepFaultPhenType
		},
		faultPhenOptions() {
			// 特约维修故障现象
			if (!this.obj?.authRepFaultPhen) return []
			return this.obj?.authRepFaultPhen
		},
		faultPhenTypeText() {
			if (!this.faultPhen) return ""

			for (const option of this.faultPhenTypeOptions) {
				if (option.id === this.faultPhen.category) {
					return option.name
				}
			}
			return ""
		},
		faultCause() {
			// 故障原因
			if (!this.obj.faultList || !Array.isArray(this.obj.faultList)) {
				return null;
			}
			for (const item of this.obj.faultList) {
				if (item.faultType === 2) {
					return item
				}
			}
		},
		faultCauseTypeOptions() {
			// 特约维修故障原因分类
			if (!this.obj?.authRepFaultCauseType) return []
			return this.obj?.authRepFaultCauseType
		},
		faultCauseOptions() {
			// 特约维修故障原因
			if (!this.obj?.authRepFaultCause) return []
			return this.obj?.authRepFaultCause
		},
		faultCauseText() {
			if (!this.faultCause) return ""

			for (const option of this.faultCauseOptions) {
				if (option.id === this.faultCause.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultCauseTypeText() {
			if (!this.faultCause) return ""

			for (const option of this.faultCauseTypeOptions) {
				if (option.id === this.faultCause.category) {
					return option.name
				}
			}
			return ""
		},
		faultMeasures() {
			// 故障措施
			if (!this.obj.faultList || !Array.isArray(this.obj.faultList)) {
				return null;
			}

			for (const item of this.obj.faultList) {
				if (item.faultType === 3) {
					return item
				}
			}
		},
		faultMeasuresTypeOptions() {
			// 特约维修故障措施分类
			if (!this.obj?.authRepFaultMeasuresType) return []
			return this.obj?.authRepFaultMeasuresType
		},
		faultMeasuresOptions() {
			// 特约维修故障措施
			if (!this.obj?.authRepFaultMeasures) return []
			return this.obj?.authRepFaultMeasures
		},
		faultMeasuresText() {
			if (!this.faultMeasures) return ""

			for (const option of this.faultMeasuresOptions) {
				if (option.id === this.faultMeasures.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultMeasuresTypeText() {
			if (!this.faultMeasures) return ""

			for (const option of this.faultMeasuresTypeOptions) {
				if (option.id === this.faultMeasures.category) {
					return option.name
				}
			}
			return ""
		},
		faultPosition() {
			// 故障部位
			for (const item of this.obj.faultList) {
				if (item.faultType === 4) {
					return item
				}
			}
		},
		faultPositionTypeOptions() {
			// 特约维修故障部位分类
			if (!this.obj?.authRepFaultPositionType) return []
			return this.obj?.authRepFaultPositionType
		},
		faultPositionOptions() {
			// 特约维修故障部位
			if (!this.obj?.authRepFaultPosition) return []
			return this.obj?.authRepFaultPosition
		},
		faultPositionTypeText() {
			if (!this.faultPosition) return ""

			for (const option of this.faultPositionTypeOptions) {
				if (option.id === this.faultPosition.category) {
					return option.name
				}
			}
			return ""
		},
		faultPositionText() {
			if (!this.faultPosition) return ""

			for (const option of this.faultPositionOptions) {
				if (option.id === this.faultPosition.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		repairTypeText() {
			for (const option of this.repairTypeOptions) {
				if (option.id === this.obj.repairType || parseInt(option.id) === this.obj.repairType) {
					return option.name
				}
			}
			return ""
		},
		warrantyPeriodText() {
			for (const option of this.warrantyPeriodOptions) {
				if (option.id === this.obj.warrantyPeriod || parseInt(option.id) === this.obj.warrantyPeriod) {
					return option.name
				}
			}
			return ""
		},
		serviceTypeText() {
			for (const option of this.serviceTypeOptions) {
				if (option.id === this.obj.serviceType || parseInt(option.id) === this.obj.serviceType) {
					return option.name
				}
			}
			return ""
		},
		repairContractTypeText() {
			for (const option of this.repairContractTypeOptions) {
				if (option.id === this.obj.repairContractType || parseInt(option.id) === this.obj.repairContractType) {
					return option.name
				}
			}
			return ""
		},
		repairItemsText() {
			for (const option of this.repairItemOptions) {
				if (option.id === this.obj.repairItems || parseInt(option.id) === this.obj.repairItems) {
					return option.name
				}
			}
			return ""
		},
	},
	methods: {
		init(data) {
			this.visible = true;
			this.$nextTick(() => {
				setTimeout(() => {
					this.obj = {
						...data,
						faultList: data && data.faultList || []
					};
					// 处理列表数据
					this.repairData = [
						{field: "发起人", value: this.obj.createName || ""},
						{field: "创建时间", value: this.formatTimestamp(this.obj.createTime, 'yyyy-MM-dd hh:mm:ss')},
						{field: "流水号", value: this.obj.serialNumber || ""},
						{field: "维修区分", value: this.repairTypeText},
						{field: "特约维修店名", value: this.obj.dealerName || ""},
						{field: "机号", value: this.obj.machineNo || ""},
						{field: "商品编号", value: this.obj.productCode || ""},
						{field: "机型编号", value: this.obj.modelCode || ""},
						{field: "机型", value: this.obj.modelName || ""},
						{field: "出库日期", value: this.obj.outDate || ""},
						{field: "保修期结束日", value: this.obj.warrantyEndDate || ""},
						{field: "保修期", value: this.warrantyPeriodText},
						{field: "PPS号", value: this.obj.ppsNo || ""},
						{
							field: "是否需要上传发票",
							value: this.obj.invoiceRequired ? (this.obj.invoiceRequired === 1 ? "是" : "否") : ""
						},
						{field: "销售日期", value: this.obj.repairInvoice.saleDate || ""},
						{field: "发票附件", value: this.getUrlFileName(this.obj.repairInvoice.invoiceFile)},
						{field: "维修受理日期", value: this.obj.repairAcceptDate || ""},
						{field: "出发时间", value: this.obj.departureTime || ""},
						{field: "到达时间（维修开始时间）", value: this.obj.arrivalTime || ""},
						{field: "服务形式", value: this.serviceTypeText},
						{field: "维修合同形态", value: this.repairContractTypeText},
						{field: "维修作业项目", value: this.repairItemsText},
						{field: "黑白计数器读数/投影机使用时间", value: this.obj.bwCounter},
						{field: "彩色计数器读数/灯泡使用时间", value: this.obj.colorCounter},
						{field: "制版计数器读数", value: this.obj.plateCounter},
						{
							field: "是否投影仪",
							value: this.obj.isProjector ? ((this.obj.isProjector === 1 || this.obj.isProjector === '1') ? "是" : "否") : ""
						},
						{field: "使用时间", value: this.obj.repairProjector.usageHours || ""},
						{field: "除尘频率", value: this.obj.repairProjector.dustFrequency || ""},
						{field: "油烟环境", value: this.obj.repairProjector.smokeEnvironment || ""},
						{field: "箱体环境", value: this.obj.repairProjector.caseEnvironment || ""},
						{field: "其他备注", value: this.obj.repairProjector.remarks || ""},
						{field: "服务费已支付", value: this.obj.serviceFeePaid || ""},
						{field: "服务费支付公司", value: this.obj.paymentCompany || ""},
						{field: "服务费支付日期", value: this.obj.paymentDate || ""},
						{field: "预计维修完成时间", value: this.obj.expectedCompletionTime || ""},
						{field: "区域", value: this.getRegion()},
						{field: "受理时随机附件以及送修商品外观备注", value: this.obj.receptionAttachments || ""},
						{field: "用户公司", value: this.obj.customerCompany || ""},
						{field: "客户代码", value: this.obj.customerCode || ""},
						{field: "邮政编码", value: this.obj.postalCode || ""},
						{field: "送修人/保修人", value: this.obj.contactPerson || ""},
						{field: "联系电话", value: this.obj.contactPhone || ""},
						{field: "结算状态", value: (this.obj.settlementStatus === '1' ? "是" : "否") || ""},
						{field: "地址", value: this.getAddress()},
						{field: "客户应付维修费", value: this.formatPriceString(this.obj.customerRepairFee, '元/次')},
						{field: "客户应付上门费", value: this.formatPriceString(this.obj.customerVisitFee, '元/次')},
						{field: "客户应付零件费", value: this.formatPriceString(this.obj.customerPartsFee, '元/次')},
						{field: "客户应付耗材费", value: this.formatPriceString(this.obj.customerSuppliesFee, '元/次')},
						{field: "客户应付其他费用", value: this.formatPriceString(this.obj.customerOtherFee, '元/次')},
						{field: "客户其它费用说明", value: this.obj.customerOtherFeeNotes || ""},
						{field: "特约维修服务费", value: this.formatPriceString(this.obj.dealerServiceFee, '元/次')},
						{field: "委托维修服务费", value: this.formatPriceString(this.obj.entrustServiceFee, '元/次')},
						{field: "其他费用", value: this.formatPriceString(this.obj.otherFees, '元/次')},
						{field: "备注", value: this.obj.remarks || ""},
						{field: "修理完成日期", value: this.obj.repairResult.repairFinishedTime || ""},
						{field: "用户取回机器日期", value: this.obj.repairResult.pickupDate || ""},
						{field: "维修人姓名", value: this.obj.repairResult.repairOper || ""},
						{
							field: "维修结果",
							value: this.obj.repairResult ? ((this.obj.repairResult.repairStatus == 1 && this.obj.repairResult.repairStatus == '1') ? "已修复" : "其它") : ""
						},
						{field: "是否有主机/零件/选购件或耗材", value: this.getMainParts()},
						{
							field: "是否可以在零件未到货的情况下完成维修",
							value: this.obj.canRepairWithoutParts ? ((this.obj.canRepairWithoutParts === 1 || this.obj.canRepairWithoutParts === '1') ? "可以" : "不可以") : ""
						},
						{field: "Advance客户编码", value: this.obj.repairAdvance.advanceCode || ""},
						{field: "维修报告", value: ""},
						{
							field: "维修单附件",
							value: this.getAttachFileText(this.obj.sysAttachFileList || [])
						},
						{field: "发货状态", value: this.obj.trackList.length > 0 ? "已发货" : ""},
						{field: "物流变更时间", value: ""},
						{
							field: "物流发货时间",
							value: this.obj.trackList.length > 0 ? this.formatTimestamp(this.obj.trackList[0].createTime, 'yyyy-MM-dd HH:mm:ss') : ""
						},
						{field: "抽样范围", value: ""},
						{field: "用户回访结果", value: this.getFollowUpStatus()},
						{field: "用户回访记录", value: this.getFollowUpStatus() === "" ? "" : "已回访"},
						{field: "上一次维修申请费用日期", value: ""},
						{field: "发起人 ", value: this.obj.createName || ""},
						{field: "发起日期", value: this.formatTimestamp(this.obj.createTime)},
						{field: "维修能力flag", value: ""},
						{field: "已换机flag", value: ""},
						{field: "机号发票绑定flag", value: ""},
						{field: "保修时长", value: ""},
						{field: "发票保修结束日", value: ""},
						{field: "出库保修期结束日", value: ""},
						{field: "延保结束日期", value: ""},
						{field: "费用合计", value: ""},
						{field: "正在维修flag", value: ""},
						{field: "机型分类", value: ""},
						{field: "商品编号JS", value: ""},
						{field: "上一次维修费用申请日期2", value: ""},
						{field: "机型编号JS", value: ""}
					]
				}, 50)
			})
		},
		// pdf下载
		handlePdf() {
			// store.commit('SHOWLOADING');
			const loading = this.$loading({
				lock: true,
				text: "导出中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});

			const element = document.querySelector(".mrt-content");
			const header = document.querySelector(".pdf-header");
			const footer = document.querySelector(".pdf-footer");

			const title = "维修申请-填写页_" + new Date().getTime();
			const pdfLoader = new PdfLoader(element, {
				header: header,
				footer: footer,
				// outputType:'file',
				fileName: title,
				direction: "p",
				format: "a4",
				isPageMessage: true,
			});
			pdfLoader.getPdf().then((res) => {
				console.log("[ 导出成功] >", res);
				loading.close();
				this.$message.success("导出成功");
			});
		},

		// 格式化日期时间
		formatDateTime(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			return `${year}-${month}-${day} ${hours}:${minutes}`;
		},
		// 格式化时间戳
		formatTimestamp(ms, datetime) {
			const _fmt = datetime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'
			return this.$moment(new Date(ms)).format(_fmt)
		},
		getFaultyPartCategoryText(id) {
			for (const option of this.faultyPartCategoryOptions) {
				if (option.id === id) {
					return option.name
				}
			}
			return ""
		},
		getApplicationStatusText(id) {
			for (const option of this.applicationStatusOptions) {
				if (option.id === id) {
					return option.name
				}
			}
			return ""
		},
		// 审核状态
		getAuditStatusText(row) {
			if (row?.auditStatus) {
				for (const option of this.repairAuditStatusOptions) {
					if (option.id === row.auditStatus) {
						return option.name
					}
				}
			}
			return "待提交"
		},
		// 附件名称处理
		getAttachFileText(rows) {
			console.log(rows);
			let file = ""
			for (const option of rows) {
				file += (option.fileName || option.name) + ";"
			}
			return file
		},
		// 附件名称处理
		getUrlFileName(url) {
			if (!url) return ""
			return url.substring(url.lastIndexOf("/") + 1)
		},
		// 区域
		getRegion() {
			const region = this.obj.region
			if (region === 1 || region === '1') {
				return "华东"
			} else if (region === 2 || region === '2') {
				return "华南"
			} else if (region === 3 || region === '3') {
				return "华北"
			}
			return ""
		},
		// 地址合并
		getAddress() {
			return `${this.obj.provinceStr || ""}${this.obj.cityStr}${this.obj.districtStr}${this.obj.addressDetail}`;
		},
		// 价格格式化
		formatPriceString(price, unit) {
			if (price === null || price === undefined) return ""
			if (typeof price === "string" && price.trim() === "") return ""
			try {
				const p = parseInt(price)
				if (isNaN(p)) return price
				let s = p.toLocaleString("zh-CN", {style: 'currency', currency: 'CNY'})
				return `${s}${unit || ""}`
			} catch (e) {
				return price
			}
		},
		// 是否有主机/零件/选购件或耗材
		getMainParts() {
			let hasMainParts = this.obj.hasMainParts;
			if (hasMainParts === 1 || hasMainParts === '1') {
				return "主机";
			} else if (hasMainParts === 2 || hasMainParts === '2') {
				return "零件";
			} else if (hasMainParts === 3 || hasMainParts === '3') {
				return "不需要";
			}
			return "";
		},
		// 回访状态
		getFollowUpStatus() {
			if (!this.obj.repairFollow) return "";
			let followUpStatus = this.obj.repairFollow.followUpStatus;
			for (const option of followUpStatusOptions) {
				if (option.id === followUpStatus) {
					return option.name
				}
			}
			return "";
		}
	}
}
</script>
<style lang="scss" scoped>

.data-dialog /deep/ .el-dialog {
	border-radius: 10px !important;
}

.data-dialog /deep/ .el-dialog__body {
	padding: 0 20px !important;
}

.data-dialog .dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.mrt-content {
	padding: 0 80px 60px;
	background-color: white;
	color: black;

	h1 {
		font-size: 28px;
		font-weight: normal;
		text-align: center;
		margin-bottom: 15px;
		color: black;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		font-size: 16px;
		font-weight: normal;
		color: black;
		table-layout: fixed;
		margin-bottom: 20px;
	}

	table, th, td {
		border: 1.1px solid black;
	}

	th, td {
		font-weight: normal;
		padding: 6px;
		background-color: white;
		text-align: left;
		white-space: normal;
		word-wrap: break-word;
	}

	tr {
		height: 20px;
	}

	td {
		width: 150px;
	}

	.th-left {
		vertical-align: top;
		text-align: left;
		padding-left: 2ch;
	}

	.tr-height {
		height: 80px;
	}

	.tr-height160 {
		height: 160px;
	}

	.tr-height240 {
		height: 240px;
	}

	.head {
		width: 100%;
		font-size: 16px;
		font-weight: normal;
		color: black;

		.sub-title {
			display: flex;
			justify-content: space-between;
		}
	}

	.footer-left-datetime {
		font-size: 16px;
		font-weight: normal;
		color: black;
		margin-bottom: 20px;
	}
}

</style>
