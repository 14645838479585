<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="data-dialog"
		width="1200px">
		<span slot="title" class="dialog-title">
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<button class="el-button down-btn menu-btn el-button--primary" @click="handlePdf()">下载</button>
		<div class="mrt-content" ref="pdfcontent">
			<h1>理光特约维修店维修报告</h1>
			<table>
				<tr>
					<th>特约维修店名</th>
					<th colspan="2">{{ obj.dealerName || "" }}</th>
					<th>维修单流水号</th>
					<th colspan="2">{{ obj.serialNumber || "" }}</th>
				</tr>
				<tr>
					<th>机型</th>
					<th colspan="2">{{ obj.modelName || "" }}</th>
					<th>机号</th>
					<th colspan="2">{{ obj.machineNo || "" }}</th>
				</tr>
				<tr>
					<th>黑白计数器</th>
					<th colspan="2">{{ obj.bwCounter || "" }}</th>
					<th>彩色计数器</th>
					<th colspan="2">{{ obj.colorCounter || "" }}</th>
				</tr>
				<tr>
					<th>安装日期</th>
					<th colspan="2"></th>
					<th>保修期</th>
					<th colspan="2">{{ warrantyPeriodText || "" }}</th>
				</tr>
				<tr>
					<th>维修受理日期</th>
					<th colspan="2">{{ obj.repairAcceptDate || "" }}</th>
					<th>服务形式</th>
					<th colspan="2"> {{ serviceTypeText || "" }}</th>
				</tr>
				<tr>
					<th>出发时间</th>
					<th colspan="2">{{ obj.departureTime || "" }}</th>
					<th>到达时间</th>
					<th colspan="2">{{ obj.arrivalTime || "" }}</th>
				</tr>
				<tr>
					<th>修理完成日期</th>
					<th colspan="2">{{ obj.repairResult && obj.repairResult.repairFinishedTime || "" }}</th>
					<th>用户取回机器日期</th>
					<th colspan="2">{{ obj.repairResult && obj.repairResult.pickupDate || "" }}</th>
				</tr>
				<tr class="tr-height">
					<th>备注</th>
					<th colspan="2">{{ obj.remarks || "" }}</th>
					<th>受理人</th>
					<th colspan="2"></th>
				</tr>
				<tr>
					<th>用户单位</th>
					<th colspan="2">{{ obj.customerCompany || "" }}</th>
					<th>用户地址</th>
					<th colspan="2">
						{{
							(obj.provinceStr || "") + (obj.cityStr || "") + (obj.districtStr || "") + (obj.addressDetail || "")
						}}
					</th>
				</tr>
				<tr>
					<th>送修人</th>
					<th colspan="2">{{ obj.contactPerson || "" }}</th>
					<th>联系电话</th>
					<th colspan="2">{{ obj.contactPhone || "" }}</th>
				</tr>
				<tr>
					<th>故障现象</th>
					<th>序号</th>
					<th>分类</th>
					<th>现象</th>
					<th>详细说明</th>
					<th></th>
				</tr>
				<tr>
					<td></td>
					<td>{{ faultPhen ? "1" : "" }}</td>
					<td>{{ faultPhenTypeText || "" }}</td>
					<td>{{ faultPhenText || "" }}</td>
					<td>{{ faultPhen && faultPhen.remark || "" }}</td>
					<td></td>
				</tr>
				<tr>
					<th>故障原因</th>
					<th>序号</th>
					<th>分类</th>
					<th>原因</th>
					<th></th>
					<th></th>
				</tr>
				<tr>
					<td></td>
					<td>{{ faultCause ? "1" : "" }}</td>
					<td>{{ faultCauseTypeText || "" }}</td>
					<td>{{ faultCauseText || "" }}</td>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<th>维修措施</th>
					<th>序号</th>
					<th>分类</th>
					<th>措施</th>
					<th></th>
					<th></th>
				</tr>
				<tr>
					<td></td>
					<td>{{ faultMeasures ? "1" : "" }}</td>
					<td>{{ faultMeasuresTypeText || "" }}</td>
					<td>{{ faultMeasuresText || "" }}</td>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<th>序号</th>
					<th colspan="2">更换零件/耗材名称</th>
					<th colspan="2">更换零件/耗材编号</th>
					<th>更换零件/耗材数量</th>
				</tr>
				<tr v-for="(item, i) in obj.mainPartsList">
					<td>{{ i + 1 }}</td>
					<td colspan="2">{{ obj.mainPartsList[i].partConsumableName }}</td>
					<td colspan="2">{{ obj.mainPartsList[i].faultyPartSerialNumber }}</td>
					<td>{{ obj.mainPartsList[i].partConsumableQuantity }}</td>
				</tr>
				<tr>
					<th>维修结果</th>
					<th v-if="obj.repairResult && obj.repairResult.repairStatus && obj.repairResult.repairStatus == 1 && obj.repairResult.repairStatus == '1'">
						已修复
					</th>
					<th v-else-if="obj.repairResult && obj.repairResult.repairStatus && obj.repairResult.repairStatus == 2 && obj.repairResult.repairStatus == '2'">
						其它
					</th>
					<th v-else></th>
					<th rowspan="6"></th>
					<th>维修人</th>
					<th colspan="2">
						{{ obj.repairResult && obj.repairResult.repairStatus && obj.repairResult.repairOper || "" }}
					</th>
				</tr>
				<tr class="tr-height">
					<th>零件费</th>
					<th>{{ formatPriceString(obj.customerPartsFee) || "" }}</th>
					<th colspan="3">
						维修结果为“换货”的情况，特约维修店记录新机的机型和机号，新机的保修期从“用户取回日期”记录的日期开始。
					</th>
				</tr>
				<tr>
					<th>耗材费</th>
					<th>{{ formatPriceString(obj.customerSuppliesFee) || "" }}</th>
					<th>换机机型</th>
					<th colspan="2"></th>
				</tr>
				<tr>
					<th>上门费</th>
					<th>{{ formatPriceString(obj.customerVisitFee) || "" }}</th>
					<th>换机机号</th>
					<th colspan="2"></th>
				</tr>
				<tr>
					<th>其他费用</th>
					<th>{{ formatPriceString(obj.customerOtherFee) || "" }}</th>
					<th rowspan="2">用户签收（以上各项内容确认无误）</th>
					<th rowspan="2" colspan="2"></th>
				</tr>
				<tr>
					<th>费用合计</th>
					<th></th>
				</tr>
				<tr>
					<th colspan="6" class="th-inst">
						为保障您的权益，请仔细阅读以下说明：<br/>
						1.接到取机通知后，请凭送修时维修店提供的维修受理单上门取机并在现场确认维修结果后签字。<br/>
						2.自收到取机通知后90天内未上门取机，将视为客户放弃该机所有权，由维修店对该维修机进行处置。<br/>
						3.为确保服务品质，理光公司将在维修完成一个月内，对您进行电话回访，非常感谢您的配合。<br/>
						4.本维修店特此声明，本维修报告中收集的送修人个人信息，仅用于维修目的或法律规定的使用目的，不会用于其他用途。同时本维修店严格遵守关于个人信息保护的法律法规以及理光公司的相关隐私政策。送修人接受本维修站服务的，视为同意并认可该信息收集、使用行为。<br/>
					</th>
				</tr>
			</table>
		</div>
	</el-dialog>
</template>
<script>
import JsPDF from 'jspdf'
import html2Canvas from "html2canvas";
import {
	warrantyPeriodOptions,
	serviceTypeOptions,
} from "@/constant/options"

export default {
	data() {
		return {
			visible: false,
			warrantyPeriodOptions,
			serviceTypeOptions,
			obj: {}
		}
	},
	computed: {
		warrantyPeriodText() {
			for (const option of this.warrantyPeriodOptions) {
				if (option.id === this.obj.warrantyPeriod || parseInt(option.id) === this.obj.warrantyPeriod) {
					return option.name
				}
			}
			return ""
		},
		serviceTypeText() {
			for (const option of this.serviceTypeOptions) {
				if (option.id === this.obj.serviceType || parseInt(option.id) === this.obj.serviceType) {
					return option.name
				}
			}
			return ""
		},
		faultPhen() {
			if (!this.obj.faultList || !Array.isArray(this.obj.faultList)) {
				return null;
			}
			// 故障现象
			for (const item of this.obj.faultList) {
				if (item.faultType === 1) {
					return item
				}
			}
		},
		faultPhenText() {
			if (!this.faultPhen) return ""

			for (const option of this.faultPhenOptions) {
				if (option.id === this.faultPhen.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultPhenTypeOptions() {
			// 特约维修故障现象分类
			if (!this.obj?.authRepFaultPhenType) return []
			return this.obj?.authRepFaultPhenType
		},
		faultPhenOptions() {
			// 特约维修故障现象
			if (!this.obj?.authRepFaultPhen) return []
			return this.obj?.authRepFaultPhen
		},
		faultPhenTypeText() {
			if (!this.faultPhen) return ""

			for (const option of this.faultPhenTypeOptions) {
				if (option.id === this.faultPhen.category) {
					return option.name
				}
			}
			return ""
		},
		faultCause() {
			// 故障原因
			if (!this.obj.faultList || !Array.isArray(this.obj.faultList)) {
				return null;
			}
			for (const item of this.obj.faultList) {
				if (item.faultType === 2) {
					return item
				}
			}
		},
		faultCauseTypeOptions() {
			// 特约维修故障原因分类
			if (!this.obj?.authRepFaultCauseType) return []
			return this.obj?.authRepFaultCauseType
		},
		faultCauseOptions() {
			// 特约维修故障原因
			if (!this.obj?.authRepFaultCause) return []
			return this.obj?.authRepFaultCause
		},
		faultCauseText() {
			if (!this.faultCause) return ""

			for (const option of this.faultCauseOptions) {
				if (option.id === this.faultCause.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultCauseTypeText() {
			if (!this.faultCause) return ""

			for (const option of this.faultCauseTypeOptions) {
				if (option.id === this.faultCause.category) {
					return option.name
				}
			}
			return ""
		},
		faultMeasures() {
			// 故障措施
			if (!this.obj.faultList || !Array.isArray(this.obj.faultList)) {
				return null;
			}

			for (const item of this.obj.faultList) {
				if (item.faultType === 3) {
					return item
				}
			}
		},
		faultMeasuresTypeOptions() {
			// 特约维修故障措施分类
			if (!this.obj?.authRepFaultMeasuresType) return []
			return this.obj?.authRepFaultMeasuresType
		},
		faultMeasuresOptions() {
			// 特约维修故障措施
			if (!this.obj?.authRepFaultMeasures) return []
			return this.obj?.authRepFaultMeasures
		},
		faultMeasuresText() {
			if (!this.faultMeasures) return ""

			for (const option of this.faultMeasuresOptions) {
				if (option.id === this.faultMeasures.categoryDetail) {
					return option.name
				}
			}
			return ""
		},
		faultMeasuresTypeText() {
			if (!this.faultMeasures) return ""

			for (const option of this.faultMeasuresTypeOptions) {
				if (option.id === this.faultMeasures.category) {
					return option.name
				}
			}
			return ""
		},
	},
	methods: {
		init(data) {
			this.visible = true;
			this.$nextTick(() => {
				setTimeout(() => {
					this.obj = {
						...data,
						faultList: data && data.faultList || []
					};
				}, 50)
			})
		},
		// pdf下载
		handlePdf() {
			const title = this.obj ? ("理光特约维修店维修报告_" + new Date().getTime()) : ("理光特约维修店维修报告（空白）_" + +new Date().getTime());
			const opts = {
				scale: window.devicePixelRatio * 3,
				dpi: window.devicePixelRatio * 4,
				allowTaint: true,
				useCORS: true
			};
			let that = this;
			html2Canvas(this.$refs.pdfcontent, opts).then(function (canvas) {
				let imgWidth = 595.28
				let imgHeight = 860
				let pageData = canvas.toDataURL('image/jpeg', 1.0)
				let PDF = new JsPDF('', 'pt', 'a4')
				PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
				PDF.save(title + '.pdf')
				that.visible = false;
			})
		},
		formatPriceString(price, unit) {
			if (price === null || price === undefined) return ""
			if (typeof price === "string" && price.trim() === "") return ""
			try {
				const p = parseInt(price)
				if (isNaN(p)) return price
				let s = p.toLocaleString("zh-CN", {style: 'currency', currency: 'CNY'})
				return `${s}${unit || ""}`
			} catch (e) {
				return price
			}
		},
	}
}
</script>
<style lang="scss" scoped>

.data-dialog /deep/ .el-dialog {
	border-radius: 10px !important;
}

.data-dialog /deep/ .el-dialog__body {
	padding: 0 20px !important;
}

.data-dialog .dialog-title {
	display: block;
	text-align: center;

	span {
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}

	img {
		width: 28px;
		float: right;
		cursor: pointer;
	}
}

.mrt-content {
	padding: 80px 60px;
	background-color: white;
	color: black;

	h1 {
		font-size: 28px;
		font-weight: normal;
		text-align: center;
		margin-bottom: 15px;
		color: black;
	}

	table {
		width: 100%;
		border-collapse: collapse;
		font-size: 16px;
		font-weight: normal;
		color: black;
		margin-bottom: 160px;
		table-layout: fixed;
	}

	table, th, td {
		border: 1.1px solid black;
	}

	th, td {
		font-weight: normal;
		padding: 6px;
		background-color: white;
		text-align: center;
		white-space: normal;
		word-wrap: break-word;
	}

	tr {
		height: 40px;
	}

	td {
		width: 150px;
	}

	.tr-height {
		height: 80px;
	}

	.th-inst {
		text-align: left;
		white-space: normal;
		word-wrap: break-word;
	}
}

</style>
